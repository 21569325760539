import { React } from 'react';
import { useSelector } from 'react-redux'
import { useState } from 'react'

import { unauthenticateUser } from '../redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { onLogout } from '../api/auth'

import '../css/navbar.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const TopNavbar = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [protectedData, setProtectedData] = useState(null)

  const { isAuth } = useSelector((state) => state.auth)

  return (
    <nav className='topNav'>
      <div className=''>
        <div>
            <a href="/">
            <img 
              src={waveLogo}
              alt="ZwickNet Logo"
              className="logo">
            </img>
            </a>
            <a href="/" className='logoText'>ZwickNet</a>
        </div>

        {isAuth ? (
          <div>
            
            <a href='/contact' className='topNavDashLink'>
              <span>Contact</span>
            </a>

            <a href='/news' className='topNavDashLink'>
              <span>News</span>
            </a>

            <a href='https://status.zwick.au' target='blank' className='topNavDashLink'>
              <span>Status</span>
            </a>

            <a href='/dashboard' style={{float: "left"}} className='topNavDashLink'>
              <span>Dashboard</span>
            </a>

            <a href='/users/logout'  className='topNavButton'>
              <span>Logout</span>
            </a>

          </div>
        ) : (
          <div>
             <a href='/contact' className='topNavDashLink'>
              <span>Contact</span>
            </a>

            <a href='/news' className='topNavDashLink'>
              <span>News</span>
            </a>
            
            <a href='https://status.zwick.au' target='blank' className='topNavDashLink'>
              <span>Status</span>
            </a>
            
            <a href='/users/login'  className='topNavButton'>
              <span>Login</span>
            </a>

            <a href='/users/register' style={{float: "right"}} className='topNavLink'>
              <span>Register</span>
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default TopNavbar