import PublicLayout from "../../components/public-page-components/publicLayout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'




//css files and photos
import '../../css/main.css' //main css file
import background from '../../assets/greyBackground.png'
import { fetchApiStatus, fetchApiStatusInfo } from "../../api/status";

const OfflineAlert = () => {
    alert("ZwickNet Service is not currently online. ")
}

const Home = () => {
    const [protectedData, setProtectedData] = useState(null)
  
    const protectedInfo = async () => {
        try {
          const { data } = await fetchApiStatusInfo()
    
          setProtectedData(data.status)
    
        } catch (error) {
            console.log(error)
        }
      }
      useEffect(() => {
        protectedInfo()
      }, [])


      return (
        <PublicLayout>
            <Helmet>
                <title>ZwickNet</title>
            </Helmet>
        <div className="hero-image" style={{backgroundImage: `url(${background})`}}>
            <div className="hero-box">
                <h1>Welcome</h1>
                <p class="hero-box-warning">This website is currently under development! May not function as expected!</p>
                <p class="hero-box-warning">ZwickNet API is currently: {protectedData}</p>
                <div>
                    <a href="/dashboard">Dashboard<span class="icon-external-link"></span></a>
                </div>
            </div>

        </div>
        <div className="content"><h1>Welcome</h1>
            <hi>ZwickNet is currently under construction. If you need to contact the website owner, please visit <a href="/contact">this</a> page.</hi>
            <h1>{process.env.REACT_APP_API_URL}</h1>

        </div>


        </PublicLayout>

        
    );
};
  
  export default Home