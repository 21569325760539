import { fetchProtectedInfo, onLogout } from '../api/auth'
import { useDispatch } from 'react-redux'
import { unauthenticateUser } from '../redux/slices/authSlice'




const LogoutPage = () => {
    const dispatch = useDispatch()



    const logout = async () => {
        try {
          await onLogout()
    
          dispatch(unauthenticateUser())
          localStorage.removeItem('isAuth')
        } catch (error) {
          console.log(error.response)
        }
      }

    return (
        <>
            <h1>Logout</h1>
            <p>Are you sure you want to logout?</p>
            <a href='#' onClick={logout}>
              <span>Yes</span>
            </a>
        </>
    );
};
  
  export default LogoutPage